import * as React from "react"
import Layout from "../components/layouts/index-layout"
import { MDXProvider } from "@mdx-js/react"
import { Link } from "gatsby"
import { ContactEmail } from "../components/site/ContactEmail"
import EmailUs from "../components/site/EmailUs"

import SEO from "../components/seo"
import { MDXRenderer } from "gatsby-plugin-mdx"

const shortcodes = { Link, ContactEmail, EmailUs }

export default ({ pageContext }) => {
  const {title, description} = pageContext.frontmatter
  return (
    <Layout>
      <SEO
        title={title}
        description={description}
      />
      <article className="prose">
        <MDXProvider components={shortcodes}>
      <MDXRenderer>{pageContext.body}</MDXRenderer>
        </MDXProvider>
      </article>
    </Layout>
  )
}
