import * as React from "react"
import {useState, useEffect } from "react"

export const ContactEmail = ({ tag }) => {
  const [contactEmail, setContactEmail] = useState("")
  tag = tag || ""
  if (tag && !tag.startsWith("-")) {
    tag = "-" + tag
  }
  useEffect(() => {
    let now : any = {}
    new Intl.DateTimeFormat('en-US', {
      timeZone: 'UTC',
      year: 'numeric',
      month: 'short',
      day: '2-digit'
    }).formatToParts(Date.now()).forEach(x => { now[x.type] = x.value})

    setContactEmail(`${now.month}${now.day}${tag}@${now.year}.contact.wordtothewise.com`)
  })
  return (<a href={"mailto:" + contactEmail} className="text-blue-700 hover:underline">{contactEmail}</a>)
}